export const useFavoriteStore = () => {
    const runtimeConfig = useRuntimeConfig()
    const userStore = useUserStore()
    const stateKey = 'userFavoritesState'
    const state = useState(stateKey, () => {
      return {
        initialized: false,
        products:  [],
        clothes:   [],
        creators:  [],
        suppliers: []
      }
    })

    const reloadFavorites = async () => {
        if (!userStore.isLoggedIn()) {
            state.value.initialized = false;
            return false
        }
        const { data, pending, error, refresh } = await useFetch(`${runtimeConfig.public.apiBase}/api/favorites`, {
            headers: {Authorization: `Bearer ${userStore.getToken()}`},
            method: 'GET'
        });
        if (error.value) {
            userStore.logoutHandler()
            navigateTo('/signin')
        } else if ('payload' in data.value && '_errors' in data.value.payload) {
            state.value.initialized = false;
            return false
        } else {
            state.value.products  = data.value.payload.products
            state.value.clothes   = data.value.payload.clothes
            state.value.creators  = data.value.payload.creators
            state.value.suppliers = data.value.payload.suppliers
            state.value.initialized = true;
            return true
        }      
    }

    const getFavorites = async (target) => {
        if (!userStore.isLoggedIn()) {
            state.value.initialized = false;
            return false
        }
        if (!state.value.initialized) await reloadFavorites()
        return (target && state.value.hasOwnProperty(target)) ? state.value[target] : state.value
    }

    const isFavorite = (target, target_id) => {
        if (!userStore.isLoggedIn()) {
            state.value.initialized = false;
            return false
        }
        if (!state.value.hasOwnProperty(target) || !target_id) {
            return false
        }
        if (!state.value[target] || !Array.isArray(state.value[target])) {
            return false
        }
        return (state.value[target].indexOf(target_id)) >= 0
    }

    const addFavorite = async (target, target_id) => {
        if (!userStore.isLoggedIn()) {
            state.value.initialized = false;
            return false
        }
        if (!state.value.hasOwnProperty(target) || !target_id) {
            return false
        }
        const { data } = await useFetch(`${runtimeConfig.public.apiBase}/api/favorites`, {
            headers: {Authorization: `Bearer ${userStore.getToken()}`},
            method: 'POST',
            body: { target: target, target_id: target_id },
        });
        if ('_errors' in data.value.payload) {
            state.value.initialized = false;
            return false
        } else {
            if (state.value[target].indexOf(target_id) < 0) {
                state.value[target].push(target_id)
            }
            return true
        }
    }

    const delFavorite = async (target, target_id) => {
        if (!userStore.isLoggedIn()) {
            state.value.initialized = false;
            return false
        }
        if (!state.value.hasOwnProperty(target) || !target_id) {
            return false
        }
        const { data } = await useFetch(`${runtimeConfig.public.apiBase}/api/favorites/del`, {
            headers: {Authorization: `Bearer ${userStore.getToken()}`},
            method: 'POST',
            body: { target: target, target_id: target_id },
        });
        if ('_errors' in data.value.payload) {
            state.value.initialized = false;
            return false
        } else {
            const idx = state.value[target].indexOf(target_id)
            if (idx >= 0) state.value[target].splice(idx, 1)
            return true
        }      
    }

    return {
        reloadFavorites,
        getFavorites,
        isFavorite,
        addFavorite,
        delFavorite,
    }
}